
// Add any submission form script below
import '../_vendor/_bigpicture';

let currentSlide = 0;
const slides = document.querySelectorAll('.slide');

function showSlide(index) {
    slides.forEach((slide, i) => {
	   slide.classList.toggle('active', i === index);
    });
}

function nextSlide() {
    currentSlide = (currentSlide + 1) % slides.length;
    showSlide(currentSlide);
}

// Initial display
showSlide(currentSlide);

// Automatically transition every 5 seconds
setInterval(nextSlide, 6000);